import React, { useEffect } from "react"
import Meta from "../utilities/seo"
import { useStaticQuery, graphql } from "gatsby"

const Ele = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "xeno-ke-client-agreement" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  useEffect(() => {
    data.allFile.edges.forEach(file => {
      if (file.node.name == "xeno-ke-client-agreement") {
        let url = "https://ke.myxeno.com" + file.node.publicURL
        window.location.href = url
      }
    })
  }, [])
  return (
    <>
      <Meta title="XENO Client Agreement" />
    </>
  )
}

export default Ele
